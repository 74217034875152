/* eslint-disable sonarjs/cognitive-complexity */
import { useState, useEffect, useMemo, useRef } from "preact/compat";

import Modal from "@components/Modal";
import Selected from "@components/Selected";
import { SearchInput } from "@components/SearchInput";
import { getGeo, type CountriesWithDeliveryEstimationsType } from "@lib/tangem";

import "./DeliveryInfo.scss";
import useModal from "src/hooks/useModal";
import { useFocusVisible } from "src/hooks/useFocusElement";
import { countryInputText, isPastel, isVivid } from "../model";

type Props = {
  textEstimation: string;
  textBusinessDays: string;
  labelSearchText: string;
  labelPlaceholderText: string;
  countriesWithEstimations: CountriesWithDeliveryEstimationsType[];
  noResultsFoundText: string;
  deliveryDelayText: string;
  chooseCountryText: string;
};

export const DeliveryInfo = ({
  textEstimation,
  textBusinessDays,
  labelSearchText,
  labelPlaceholderText,
  countriesWithEstimations,
  noResultsFoundText,
  deliveryDelayText,
  chooseCountryText,
}: Props) => {
  const sortedCountries = useMemo(
    () => countriesWithEstimations.sort((a, b) => a.name.localeCompare(b.name)),
    [countriesWithEstimations],
  );

  const pastelAndVividDelay = ["us"];
  const pastelDelay = [
    "uk",
    "ca",
    "at",
    "be",
    "bg",
    "hr",
    "cy",
    "cz",
    "dk",
    "ee",
    "fi",
    "fr",
    "de",
    "gr",
    "hu",
    "ie",
    "it",
    "lv",
    "lt",
    "lu",
    "mt",
    "nl",
    "pl",
    "pt",
    "ro",
    "sk",
    "si",
    "es",
    "se",
  ];

  const [countryList, setCountryList] = useState(sortedCountries);
  const [daysFrom, setDaysFrom] = useState<number | null>(null);
  const [daysTo, setDaysTo] = useState<number | null>(null);
  const [activeCountry, setActiveCountry] = useState<Record<string, string>>();
  const scrollableListElement = useRef<HTMLUListElement | null>(null);

  const { isShowing, hide, show } = useModal("search", "");
  const searchInputRef = useFocusVisible<HTMLInputElement>([isShowing]);

  useEffect(() => {
    scrollableListElement.current?.scrollTo({ top: 0, behavior: "instant" });
  }, [isShowing]);

  useEffect(() => {
    getGeo().then(({ code }) => {
      const countryByGeo = countryList.find((country) => country.code === code);

      if (countryByGeo) {
        setActiveCountry({ code, name: countryByGeo.name });
        const { from, to } = countriesWithEstimations.find(
          (country) => country.code === code,
        )!;
        setDaysFrom(from);
        setDaysTo(to);
      }
    });
  }, []);

  useEffect(() => {
    if (!activeCountry) return;
    const { from, to } = countriesWithEstimations.find(
      (country) => country.code === activeCountry.code,
    )!;
    setDaysFrom(from);
    setDaysTo(to);
  }, [activeCountry]);

  function handleCountrySelect(code: string, name: string) {
    setActiveCountry({
      code,
      name,
    });
    handleModalClose();
  }

  const handleCountryChange = (inputText: string) => {
    const value = inputText.toLowerCase().trim();
    if (value === "") {
      // to prevent flickering of list and clearing input delay
      setTimeout(() => {
        setCountryList(sortedCountries);
      });
      return;
    }
    const filtered = sortedCountries
      .filter(({ name }) => name.toLowerCase().includes(value))
      .sort(
        (a, b) =>
          a.name.toLowerCase().indexOf(value) -
          b.name.toLowerCase().indexOf(value),
      );
    setCountryList(filtered.slice(0, 5));
  };

  const handleModalClose = () => {
    hide();
    setCountryList(sortedCountries);
    countryInputText.value = "";
  };

  return (
    <>
      {(pastelAndVividDelay.includes(activeCountry?.code ?? "") &&
        (isVivid.value || isPastel.value)) ||
      (pastelDelay.includes(activeCountry?.code ?? "") && isPastel.value) ? (
        <p class="delivery__text">{deliveryDelayText}</p>
      ) : (
        <>
          <p class="delivery__text">
            <span>{textEstimation}:</span>
            {daysFrom ? (
              <>
                <span className="text_white">
                  {" "}
                  {daysFrom}-{daysTo}{" "}
                </span>

                <Selected text={textBusinessDays} selectedClass="text_white" />
              </>
            ) : null}
            &nbsp;
            <button
              type="button"
              onClick={show}
              class="text_white text_underline-dashed delivery__button"
            >
              {activeCountry?.name ?? chooseCountryText}
            </button>
          </p>
          <Modal isShowing={isShowing} hide={handleModalClose} isCentered>
            <div className="popup__container">
              <button
                className="popup__close-button"
                onClick={handleModalClose}
                aria-label="Close modal"
              ></button>
              <div className="search__wrapper ">
                <SearchInput
                  model={countryInputText}
                  labelText={labelSearchText}
                  placeholdertext={labelPlaceholderText}
                  onSearchChange={handleCountryChange}
                  onReset={() => setCountryList(sortedCountries)}
                  id="country"
                  className="search__container"
                  searchRef={searchInputRef}
                />
              </div>
              {countryList.length > 0 ? (
                <div className="country-list__wrapper">
                  <ul
                    className="country-list__list"
                    ref={scrollableListElement}
                  >
                    {activeCountry && (
                      <li key={activeCountry.code}>
                        <button
                          className="country-list__item country-list__item_selected"
                          onClick={handleModalClose}
                        >
                          <img
                            src={`/svg/flags/${activeCountry.code}.svg`}
                            alt="flag"
                            width={24}
                            height={24}
                            className="country-list__img"
                          />
                          <span className="country-list__text">
                            {activeCountry.name}
                          </span>
                        </button>
                      </li>
                    )}
                    {countryList.map(({ code, name }) =>
                      code === activeCountry?.code ? null : (
                        <li key={code}>
                          <button
                            className="country-list__item"
                            onClick={() => handleCountrySelect(code, name)}
                          >
                            <img
                              src={`/svg/flags/${code}.svg`}
                              alt="flag"
                              width={24}
                              height={24}
                              className="country-list__img"
                            />
                            <span className="country-list__text">{name}</span>
                          </button>
                        </li>
                      ),
                    )}
                  </ul>
                </div>
              ) : (
                <span className="country-list__empty">
                  {noResultsFoundText}
                </span>
              )}
            </div>
          </Modal>
        </>
      )}
    </>
  );
};
